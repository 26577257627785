export default defineNuxtRouteMiddleware((to, _) => {
  const sSlug = to.params.sSlug

  if (!sSlug || Array.isArray(sSlug) || !redirectJson[sSlug]) return

  return navigateTo(
    {
      path: to.path.replace(/(s-)([a-z]+)/g, `$1${redirectJson[sSlug]}`),
      query: to.query,
    },
    {
      redirectCode: 301,
    }
  )
})

const redirectJson: { [key: string]: number } = {
  nagoya: 23106,
  sapporo: 1101,
  sendai: 4101,
  torii: 14104,
  shinagawa: 13109,
  ginza: 13102,
  shinjuku: 13104,
  nogizaka: 13103,
  suehiro: 13101,
  matsuyama: 38201,
  kanazawa: 17201,
  kobe: 28110,
  omiya: 11103,
  yokohama: 14104,
  takasu: 13122,
  hakata: 40132,
  esaka: 27205,
  shinsaibashi: 27128,
  hirakata: 27210,
  himeji: 28201,
  takasaki: 10202,
  takadanobaba: 13104,
  nakano: 13114,
  utsunomiya: 9201,
  mito: 8201,
  ikebukuro: 13116,
  kokura: 40106,
  hamamatsu: 22131,
  morioka: 3201,
  asahikawa: 1204,
  umeda: 27128,
  kyobashi: 13102,
  harada: 40217,
  toyohashi: 23201,
  hakodate: 1202,
  funabashi: 12204,
  kashiwa: 12217,
  tachikawa: 13202,
  kitakyushu: 40106,
  takamatsu: 37201,
  fukuyama: 34207,
  koriyama: 7203,
  obihiro: 1207,
  sannomiya: 28110,
  kawagoe: 11201,
  tama: 14104,
  shibuya: 13113,
  otsuka: 13105,
  machida: 13209,
  hachioji: 13201,
  omotesando: 13113,
  kurume: 40203,
  tenjin: 40133,
  yonago: 31202,
  hachinohe: 2203,
  sakai: 27141,
  suita: 27205,
  nishiumeda: 27127,
  tennoji: 27109,
  namba: 27128,
  nishinomiya: 28204,
  kawaguchi: 11203,
  shinyokohama: 14109,
  kawasaki: 14131,
  fujisawa: 14205,
  kichijoji: 13203,
  harajuku: 13113,
  jiyugaoka: 13110,
  akihabara: 13106,
  ueno: 13106,
  shinbashi: 13103,
  setagaya: 13112,
  kitasenju: 13121,
  naha: 47201,
  kurashiki: 33202,
  shimonoseki: 35201,
  okazaki: 23202,
  kanayama: 23109,
  hirosaki: 2202,
  uozu: 16204,
  maebashi: 10201,
  matsudo: 12207,
  roppongi: 13103,
  futakotamagawa: 13112,
  akabane: 13117,
  ebisu: 13113,
  hashimoto: 14151,
  yokosuka: 14201,
  kofu: 19201,
  tsu: 24201,
  otsu: 25201,
  matsue: 32201,
}
